.hero {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("https://harekrishnajaipur.org/media/landingpage/Annadaan_BHQJEmh.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    padding: 0 2rem;
    text-align: center;
  }
  
  .animated-text {
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-wrap: pretty;
  }
  
  .cursor {
    display: inline-block;
    background-color: #333;
    width: 2px;
    height: 1.2rem;
    margin-left: 2px;
    vertical-align: middle;
    animation: blink-cursor 0.7s step-start infinite;
  }
  
  .blinking {
    opacity: 0;
  }
  
  .inspiring-lines {
    margin-top: 2rem;
  }
  
  .inspiring-lines p {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    color: #555;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  .inspiring-lines p:nth-child(1) {
    animation-delay: 1s;
  }
  
  .inspiring-lines p:nth-child(2) {
    animation-delay: 1.5s;
  }
  
  .inspiring-lines p:nth-child(3) {
    animation-delay: 2s;
  }
  
  .inspiring-lines p:nth-child(4) {
    animation-delay: 2.5s;
  }
  
  /* Typing bar animation */
  @keyframes blink-cursor {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  /* Fade-in animation for inspiring lines */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .animated-text {
      font-size: 2rem;
    }
  
    .inspiring-lines p {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .animated-text {
      font-size: 1.5rem;
    }
  
    .inspiring-lines p {
      font-size: 0.9rem;
    }
  }
  