.appbar-container {
    font-family: 'Arial', sans-serif;
}
.container-fluid{
    flex-wrap: nowrap !important;
}
.appbar-navbar {
    background-color: #343a40; /* Dark gray for a professional look */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.iskconLogo {
    height: 100px;
    width: auto;
    margin-right: 15px;
}
.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
}
.appbar-brand {
    font-size: 1.25rem;
    color: black !important; /* White text color for contrast */
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
    color: black !important;
}

.appbar-nav {
    display: flex;
    align-items: center;
}

.appbar-nav-item {
    margin: 0 10px;
}

.appbar-nav-link {
    color: #000000 !important; 
    font-weight: 500;
    position: relative;
    transition: color 0.3s ease, transform 0.3s ease;
}

.appbar-nav-link:hover,
.appbar-nav-link:focus {
    color: #f8f9fa !important; /* Light color on hover */
    transform: translateY(-2px);
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link{
    background: #e7e8e7;
    border-radius: 6px;
}

.appbar-nav-link.active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 2px;
    transition: width 0.3s ease;
    width: 100%;
    background: #e7e7e7;
    border-radius: 6px;
}
.appbar-nav-link.active .nav-item {
    background: #e7e7e7;
    border-radius: 6px;
}
.appbar-dropdown {
    background-color: #e1e1e1; /* Slightly lighter gray for dropdown */
    border: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.appbar-dropdown-item {
    color: #adb5bd !important; /* Light gray text color */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.appbar-dropdown-item:hover,
.appbar-dropdown-item:focus {
    background-color: #343a40 !important; /* Dark gray on hover */
    color: #6b6363 !important; /* Keep white text color */
}

.appbar-dropdown-item.active {
    background-color: #343a40 !important; /* Dark gray for active item */
    font-weight: bold;
    color: black;
}

@media screen and (max-width: 768px) {
    .appbar-nav-link {
        background-color: rgba(255, 255, 255, 0.1) !important; /* Semi-transparent white background */
        padding: 10px 15px;
        border-radius: 8px;
        margin: 5px 0;
    }

    .appbar-nav-item {
        width: 100%;
    }

    .appbar-nav-link.active::after {
        background-color: grey; /* Light gray underline for active link */
        transition: width 0.3s ease;
        border: 0.1px solid grey;
        color: black;
    }

    .navbar-collapse {
        border: 1px solid #adb5bd; /* Light gray border */
        border-radius: 10px;
        padding: 15px;
        background: rgba(255, 255, 255, 0.9); /* Semi-transparent dark background */
        transition: max-height 0.3s ease, opacity 0.3s ease; /* Smooth opening/closing */
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }

    .navbar-collapse.show {
        max-height: 1000px; /* A large enough value to show the menu */
        opacity: 1;
    }

    .appbar-dropdown {
        background-color: #f7fbff;
        width: 100% !important;
        margin: 0;
        padding: 0;
    }

    .appbar-dropdown-item {
        color: #4e565d !important; /* Light gray text color */
        padding: 10px 15px;
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-up {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.dropdown-menu.show {
    animation: slide-down 0.3s ease-in-out;
}

.dropdown-menu.hide {
    animation: slide-up 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
    .appbar-brand {
        white-space: normal;
    }
}